import React from "react";
import { AppLogo } from "../../../ui";
import styled from "styled-components";
import { mobileSize } from "../../../../util/variables";
import { SegmentI } from "./FreeTrialSegment";
import { NoTrialSegment } from "./NoTrialSegment";
import theme from "../../../../util/theme";
import PrepaymentHeroImage from "./PrepaymentHeroImage";

export const PrepaymentHeroNoTrial: React.FC<Omit<SegmentI, "id">> = ({ handleRedirect, loading, priceData }) => {
  return (
    <Background>
      <Container>
        <Logo>
          <AppLogo size="medium" />
        </Logo>
        <NoTrialSegment
          heroVersion
          id="no-trial-1"
          priceData={priceData}
          handleRedirect={handleRedirect}
          loading={loading}
        />
      </Container>
      <PrepaymentHeroImage />
    </Background>
  );
};

const Background = styled.div`
  background-color: ${theme.WHITE_COLOR};
  padding: 96px 0px 40px 0px;
  @media ${mobileSize} {
    padding: 40px 0px 32px 0px;
  }
`;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  /* gap: 18px; */
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  text-align: center;

  @media ${mobileSize} {
    margin-bottom: 8px;
    text-align: left;
  }
`;

const Logo = styled.div`
  position: absolute;
  left: 56px;
  top: 85px;
  @media ${mobileSize} {
    position: static;
    width: 96px;
  }
`;
