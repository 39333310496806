import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import theme from "../../../../util/theme";
import { AppLoadingButton, AppTitle2 } from "../../../ui";
import { laptopSize, mobileSize } from "../../../../util/variables";
import { useInView } from "../../../../hooks/useInView";
import { web_acq_prepayment_section_view } from "../../../../services/mixpanel/acquisition-events";
import { useOnboardingContext } from "../../onboarding/OnboardingContext";
import { PriceDataT } from "../../../../util/types";

export interface SegmentI {
  id: string;
  handleRedirect: () => void;
  loading: boolean;
  heroVersion?: boolean;
  priceData: PriceDataT;
}

export const NoTrialSegment: React.FC<SegmentI> = ({
  id,
  handleRedirect,
  priceData,
  loading,
  heroVersion, // used for the prepayment hero section
}) => {
  const { customGroup } = useOnboardingContext();
  const { inView, ref, disconnect } = useInView({
    threshold: 0.8,
  });

  useEffect(() => {
    if (inView) {
      web_acq_prepayment_section_view(heroVersion ? "Hero" : "100% Guaranteed");
      disconnect();
    }

    return () => {
      disconnect();
    };
  }, [inView, disconnect, heroVersion]);

  return (
    <Container ref={ref} id={id} heroVersion={heroVersion}>
      <Content heroVersion={heroVersion}>
        <H2>Subscribe {heroVersion ? "to Imprint" : "now and save"}</H2>
        {heroVersion && (
          <SegmentText style={{ marginTop: 16 }}>
            Unlock unlimited visual learning, today.
          </SegmentText>
        )}
        <GuaranteedWrapper>
          <H3>100% Guaranteed</H3>
          <SegmentText style={{ maxWidth: 700 }}>
            If at any point in the first 7 days you decide Imprint isn&apos;t for you, we&apos;ll
            give you a full refund, no questions asked.
          </SegmentText>
        </GuaranteedWrapper>
        <SegmentText color={theme.SLATE_COLOR_3}>
          {heroVersion && "Subscribe now and save. "} Get a year of Imprint for just{" "}
          <s style={{ fontWeight: 300 }}>
            {priceData.currency.symbol}
            {priceData.annual_price}
          </s>{" "}
          {priceData.currency.symbol}
          {priceData.discounted_annual_price}.
        </SegmentText>
        <AppLoadingButton
          type="button"
          disabled={loading}
          loading={loading}
          style={{ margin: "16px auto 0 auto" }}
          onClick={handleRedirect}
        >
          Get Imprint
        </AppLoadingButton>
      </Content>
    </Container>
  );
};

const Container = styled.section<{ heroVersion?: boolean }>`
  background-color: ${({ heroVersion }) => (heroVersion ? theme.WHITE_COLOR : theme.PRIMARY_COLOR)};
  ${props =>
    !props.heroVersion &&
    css`
      padding: 80px;
      @media ${laptopSize} {
        padding: 64px;
      }
      @media ${mobileSize} {
        padding: 64px 24px;
      }
    `}
`;

const Content = styled.div<{ heroVersion?: boolean }>`
  max-width: 1200px;
  padding: 56px 88px;
  margin: 0 auto;
  border-radius: 16px;
  background-color: ${theme.WHITE_COLOR};
  /* box-shadow: 0px 2px 8px rgba(164, 164, 164, 0.25); */
  font-family: ${theme.SECONDARY_FONT};

  @media ${laptopSize} {
    padding: 36px 56px;
  }

  @media ${mobileSize} {
    padding: ${props => (props.heroVersion ? "32px 0" : "32px 16px 32px 24px")};
  }
`;

const H2 = styled(AppTitle2 as any)`
  text-align: center;
  color: ${theme.BLACK_COLOR};
  margin: 0 auto;
  @media ${mobileSize} {
    max-width: 12ch;
  }
`;

const H3 = styled.h3`
  margin: 0;
  text-transform: uppercase;
  font-size: 36px;
  color: ${theme.BLACK_COLOR};
  @media ${mobileSize} {
    font-size: 24px;
  }
`;

const GuaranteedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 32px 16px;
  margin: 56px 0;
  border-radius: 16px;
  background-color: #EDEEEF;
  width: 100%;
  @media ${mobileSize} {
    padding: 24px 16px;
    margin: 24px 0;
  }
`;

interface SegmentTextProps {
  color?: string;
  size?: number;
  mobileSize?: number;
  weight?: number;
}

const SegmentText = styled.p<SegmentTextProps>`
  margin: 0;
  text-align: center;
  font-size: ${props => props.size ?? 24}px;
  font-family: ${theme.SECONDARY_FONT};
  line-height: ${props => (props.size ? props.size + 6 : 32)}px;
  color: ${props => props.color ?? theme.BLACK_COLOR};
  ${props =>
    props.weight &&
    css`
      font-weight: ${props.weight};
    `};

  @media ${mobileSize} {
    font-size: ${props => props.mobileSize ?? 16}px;
    line-height: ${props => (props.mobileSize ? props.mobileSize + 6 : 22)}px;
  }
`;
