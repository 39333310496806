import axios from "../services/axios";
import { GATSBY_BE_URL } from "../util/variables";

export const redirectToStripe = async ({
  subscriptionType,
  priceId,
  couponId,
  trial = false,
  email,
  uid,
  currency_code,
}: {
  subscriptionType: string;
  priceId?: string | undefined;
  couponId?: string | undefined;
  trial?: boolean;
  email?: string;
  uid?: string;
  currency_code: string;
}) => {
  const data: any = {};
  data.subscription_type = subscriptionType;
  // data.date = moment().format("YYYY-MM-DD");
  data.priceId = priceId;
  data.cid = couponId;
  data.trial = trial;
  data.email = email;
  data.uid = uid;
  data.cancel_url = typeof window === "undefined" ? undefined : window.location.href;
  data.currency = currency_code;

  const res = await axios.post(`${GATSBY_BE_URL}/registerStripeUser`, JSON.stringify(data));
  const url = await res.data;
  localStorage.setItem("stripe_url", "true");
  return url;
};

export const getStripeDiscountURL = async ({
  email,
  uid,
  product_id,
  cancel_query_params,
  multiple_plans,
  currency_code,
}: {
  email: string;
  uid: string;
  product_id?: string;
  cancel_query_params?: { [key: string]: string };
  multiple_plans?: boolean;
  currency_code: string;
}) => {
  const res = await axios.post(
    `${GATSBY_BE_URL}/createDiscountStripeUrl`,
    JSON.stringify({
      email,
      uid,
      product_id,
      cancel_query_params,
      multiple_plans,
      currency: currency_code,
    }),
  );
  const url = await res.data;
  return url;
};

export const getStripeProductPrice = async (priceId: string) => {
  const res = await axios.get(`${GATSBY_BE_URL}/getStripeProductPrice`, { params: { priceId } });
  // convert from cents to dollars
  return res.data / 100;
};

export const getCouponData = async (couponId: string) => {
  const res = await axios.get(`${GATSBY_BE_URL}/getCouponData`, { params: { couponId } });
  const { amount_off, percent_off, duration } = res.data;
  return {
    amount_off: amount_off ? amount_off / 100 : 99.99 * (percent_off / 100),
    duration,
  };
};

/**
 * Get's the customers payment data after completing Stripe checkout
 * @param sessionId Stripe session ID
 * @returns object: email, customer id, and subscription id
 */
export const getStripePaymentData = async (sessionId: string) => {
  try {
    const res = await axios.get(`${GATSBY_BE_URL}/getStripePaymentData`, {
      params: { session_id: sessionId },
    });
    return res.data;
  } catch (err) {
    console.error(err);
    throw new Error(`Error getting stripe payment data: ${err}`);
  }
};
